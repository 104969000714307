@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .bg-hero {
    background-image: url("../Images/split_background.svg");
  }
  .bg-green-hero {
    background-color: #059669;
  }
}
